<template>
  <div class="forgot-password min-h-screen container py-16 lg:p-32 flex items-center justify-center">
    <div class="login-container shadow-login rounded max-w-full flex flex-grow-0 flex-col items-center justify-center bg-white py-10 px-5 sm:p-10">
      <logo class="mx-auto mb-1" />

      <h1 class="mb-12">acesso ao painel de gestão</h1>

      <div class="login-form-container flex flex-col items-center justify-start">
        <h4 class="self-start font-light text-lg text-black opacity-40 mb-4">Esqueci minha senha</h4>

        <nivea-form v-if="!submitted" :submit="submit" class="flex flex-col items-center justify-center">
          <field name="email" label="E-mail" rules="required|email" v-slot="{ label }">
            <v-text-field type="email" v-model="form.email" :label="label" autocomplete="username" hide-details="auto" />
          </field>

          <Button class="w-36 mt-10" type="submit" :loading="loading">Enviar</Button>
        </nivea-form>

        <p v-else class="text-blue text-lg">Fantástico. Se o seu e-mail estiver cadastrado conosco, você vai receber uma mensagem com um link para criar uma nova senha.</p>
      </div>

      <Button isLink :to="{ name: 'AdminLogin' }">Retornar ao login</Button>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api.js';

import Logo from "@/components/Logo";
import NiveaForm from '@/components/NiveaForm';
import Field from "@/components/fields/Field";
import Button from "@/components/Button";

export default {
  name: "AdminForgotPassword",
  components: {
    Logo,
    NiveaForm,
    Field,
    Button,
  },
  data() {
    return {
      submitted: false,
      loading: false,
      form: {
        email: '',
      }
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      await api.post('admin/forgot-password', this.form);

      this.loading = false;
      this.submitted = true;
    }
  },
};
</script>

<style lang="scss">
.forgot-password {
  .login-form-container {
    min-height: 191px;

    .button--is-link {
      @apply mt-8;
    }
  }
}
</style>
